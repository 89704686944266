import { Button, Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getUsersScoreBingo } from '../../../apis/games';
import UserScoreDetailModal from './UserScoreDetailModal';
import ButtonLoader from '../../ButtonLoader';
import UserDeleteModal from './UserDeleteModal';

const UserScoreTableBingo = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [usersScore, setUsersScore] =useState([]);
  const [usersScoreDetail, setUsersScoreDetail] =useState([]);
  const [detailModalOpen, setDetailModalOpen] =useState(false)
  const [deleteModalOpen, setDeleteModalOpen] =useState(false)
  const [currentUser, setCurrentUser] =useState(null);
  const [userLoading, setUserLoading] =useState(false);

  const BingoScoresColumns = [
    { id: "full_name", label: "USERNAME", minWidth: 200 },
    { id: "email", label: "EMAIL", minWidth: 200 },
    {id:'details', label:"DETAILS",minWidth: 200},
    {id:'delete', label:"DELETE",minWidth: 200}
  ];

  const handleChangePage = (event, newPage) => {
    console.log("page No:", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const getUsersScores = async () =>{
    setUserLoading(true);
    const data = await getUsersScoreBingo(page+1,rowsPerPage);
    setUsersScoreDetail(data);
    setUsersScore(data?.data);
    setUserLoading(false);
  }

  const handleDetailModalOpen = (user_id) =>{
    setCurrentUser(user_id);
    setDetailModalOpen(true)
  }
  const handleDetailModalClose = () =>{
    setDetailModalOpen(false);
  }
  const handleDeleteModalOpen = (user_id) =>{
    setCurrentUser(user_id);
    setDeleteModalOpen(true);
  }
  const handleDeleteModalClose = () =>{
    setDeleteModalOpen(false);
  }
  useEffect(()=>{
    getUsersScores()
  },[page, rowsPerPage])

  return (
    <>
    <Grid >
      <Table>
        <TableHead>
          <TableRow>
            {BingoScoresColumns?.map((item)=>(
              <TableCell key={item.id} style={{
                      border: "none",
                      fontSize: 12,
                      fontWeight: 600,
                      color: "#8086A2",
                    }}>{item.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {userLoading ? <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell><ButtonLoader/></TableCell>
          <TableCell></TableCell>
        </TableRow> :
          (usersScore?.map((user_item)=>(
            <TableRow key={user_item?.id}>{
              BingoScoresColumns?.map((column) => (
                <TableCell key={column?.id}>{
                  column?.id === "details" ? <Button onClick={()=> handleDetailModalOpen(user_item?.user_id)}>Details</Button> :
                  column?.id === "delete" ?<Button onClick={()=> handleDeleteModalOpen(user_item?.user_id)}>Delete</Button> :user_item[column?.id]
                }</TableCell>
              ))
            }</TableRow>
          )))
        }
        </TableBody>
      </Table>
      <TablePagination
          rowsPerPageOptions={[8, 15, 30]}
          component="div"
          count={usersScoreDetail?.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Grid>
    {
      detailModalOpen && <UserScoreDetailModal modalOpen={detailModalOpen} handleClose={handleDetailModalClose} currentUser={currentUser}/>
    }
    {
      deleteModalOpen && <UserDeleteModal modalOpen={deleteModalOpen} handleClose={handleDeleteModalClose} currentUser={currentUser} getUsersScores={getUsersScores}/>
    }
    </>
  )
}

export default UserScoreTableBingo;