import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import style from "./game.module.css"
import gameLogo from "../../images/icons/gameLogo.jpg";
import bingoImg from "../../images/icons/Bingo_icon.png";
import { useNavigate } from "react-router-dom";

const Games = () => {
    const navigate =useNavigate();
    return (
        <>
        <Box className={style.gameHeadingBox}>
                <Typography
                    component="h2"
                    sx={{ fontWeight: '700', fontSize: "2.25rem", color: "#313137" }}
                >
                    Games
                </Typography>
            </Box>
            <Box>
            <Grid container spacing={4}>
            <Grid item>
            <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          image={gameLogo}
          alt="winedle"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            WINEDLE
          </Typography>
        </CardContent>
        <CardActions>
        <Button size="small" onClick={()=> navigate("/games/winedle")}>Open</Button>
      </CardActions>
      </CardActionArea>
    </Card>
            </Grid>
            <Grid item>
            <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          image={bingoImg}
          alt="bingo"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            BINGO
          </Typography>
        </CardContent>
        <CardActions>
        <Button size="small" onClick={()=> navigate("/games/bingo")}>Open</Button>  
      </CardActions>
      </CardActionArea>
    </Card>
            </Grid>
            </Grid>
            </Box>
        </>
    )
}

export default Games;